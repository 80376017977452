<template>
  <transition name="menu">
    <form-jukdis :is-edit="false"/>
  </transition>
</template>

<script>
import formJukdis from './components/formJukdis'

export default {
  name: 'CreateJukdis',
  components: { formJukdis }
}
</script>

